import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import HomePage from '../view/home.vue'




const routes = [
    { path: '/', component:HomePage},
]

const router = new VueRouter({
    mode:'hash',
    routes
})

export default router