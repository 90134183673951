<template>
    <div id="home">
        <div class = "header">
            <div class = "container">
                <div class = "header_content">
                    <div class = "logo">
                        <img src="@/assets/p_logo.png" alt="" srcset="">
                    </div>
                    <div class = "header_home" @click="handleCenter">
                        <img src="@/assets/home_icon.png" alt="" srcset="">
                        <p>HOME</p>
                    </div>
                </div>
            </div>
        </div>
        <div class = "page">
            <div class = "container">
                <div class = "page_content">
                    <p class = "page_title">PHILIAM Point Exchange</p>
                    <div class = "page_step">
                        <div class = "step_item" :class = "stepIndex1 == 0?'active':''">
                            <div class = "item_box">
                                <div class = "item_num">1</div>
                                <p>Create Account</p>
                            </div>
                        </div>
                        <div class = "step_item" :class = "stepIndex2 == 1?'active':''">
                            <div class = "item_box">
                                <div class = "item_num">2</div>
                                <p>Enter Code</p>
                            </div>
                        </div>
                        <div class = "step_item " :class = "stepIndex3 == 2?'active':''">
                            <div class = "item_box">
                                <div class = "item_num">3</div>
                                <p>Successful</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class = "step_content">
            <div class = "container">
                <div class = "step_box">
                    <div class = "step_item" v-show = "stepIndex == 0">
                        <div class = "item_desc">
                            You have registered our website (<span @click="handleCenter">www.philiambike.com</span>) account, please click “Next Step”. <br>
                            If you do not have an account, please click below to create one.
                        </div>
                        <div class = "step_btn1" @click="handleRegister">To create your personal account</div>
                    </div>
                    <div class = "step_item" v-show = "stepIndex == 1">
                        <p class = "item_title">Enter the redemption code on the scorecard</p>
                        <div class = "form_box">
                            <div class = "form_item" :class = "codeflag?'active':''">
                                <div class = "form_box">
                                    <div class = "item_title1">Enter your code:</div>
                                    <input type="text" v-model = "code" @blur = "bindeCode" @input = "bindeCodeChange">
                                    <img src="@/assets/close_icon.png" alt="" srcset="" v-show = "codeflagfocus" @click = "handleCloseCode">
                                </div>
                                <div class = "tip" v-show = "codeflag">{{ codetext }}</div>
                            </div>
                            <div class = "form_item" :class = "emailflag?'active':''">
                                <div class = "form_box">
                                    <div class = "item_title1">Enter your Email account:</div>
                                    <input type="text" v-model = "email" @blur = "bindeEmail" @input = "bindeEmailChange">
                                    <img src="@/assets/close_icon.png" alt="" srcset="" v-show = "emailflagfocus" @click = "handleCloseEmail">
                                </div>
                                <div class = "tip" v-show = "emailflag">{{ emailtext }}</div>
                            </div> 
                        </div>
                    </div>
                    <div class = "step_item" v-show = "stepIndex == 2">
                        <div class = "item_box">
                            <img src="@/assets/success_icon.png" alt="">
                            <div class = "success_content">
                                <p class = "success_title">successfully redeemed !</p>
                                <div class = "success_desc">
                                    You have successfully redeemed, the points will be released to your account within 24 hours.<br>
                                    Please check, if there is any problems, please contact our customer service:<br>
                                    <p>service@philiambike.com.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn_content">
            <div class = "container">
                <div class = "btn_box" v-show = "stepIndex == 0">
                    <div class = "btn_item" @click = "handleNext(1)" >Next step</div>
                </div>
                <div class = "btn_box1" v-show = "stepIndex == 1">
                    <div class = "btn_item1" @click = "handlePrev(0)" >Last step</div>
                    <div class = "btn_item2 " @click = "handleSubmit" >Redeem Points</div>
                </div>
            </div>
        </div>
        <div class = "note">
            <div class = "container">
                <div class = "note_box">
                    <p class = "note_title">Notes</p>
                    <p class = "note_desc">
                        · Each redemption code can only be used once.<br>
                        . please make sure you use the code within the validity period.<br>
                        · if you encounter any problems, please contact our customer service: service@philiambike.com
                    </p>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
    name: 'App',
    data(){
        return{
            islogin:false,
            regidterUrl:'https://www.philiambike.com/account/register',
            stepIndex:0,
            stepIndex1:0,
            stepIndex2:0,
            stepIndex3:0,
            apiurl:'https://points.philiambike.com/api/index/bind_code',
            codeflag:false,
            codeflagfocus:false,
            emailflag:false,
            emailflagfocus:false,
            codetext:'',
            emailtext:'',
            code:'',
            email:'',
        }
    },
    methods:{
        handleCenter(){
            window.open('https://www.philiambike.com')
        },
        handleRegister(){
            window.open(this.regidterUrl)
        },
        handleNext(index){
            this.stepIndex = index
            this.stepIndex2 = 1
        },
        handlePrev(index){
            this.stepIndex = index
            this.stepIndex1 = index
            this.stepIndex2 = 0
        },
        handleSubmit(){
            axios.post(this.apiurl,{
                email:this.email,
                code:this.code
            })
            .then(response => {
               if(response.data.code == 0){
                    this.codetext = 'Invalid exchange code, please re-enter'
                    this.codeflag = true
               }else if(response.data.code == 1){
                    this.stepIndex3 = 2
                    this.stepIndex = 2
               }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
        },
        bindeCode(){
            if(this.code == ''){
                this.codetext = 'Input cannot be empty'
                this.codeflag = true
            }else{
                this.codeflag = false
            }
        },
        bindeCodeChange(){
            if(this.code == ''){
                this.codeflagfocus = false
            }else{
                this.codeflagfocus = true
            }
            
        },
        handleCloseCode(){
            this.code = ""
            this.codeflagfocus =  false
            this.codeflag = false
        },
        bindeEmail(){
            if(this.email == ''){
                this.emailtext = 'Input cannot be empty'
                this.emailflag = true
            }else{
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(this.email)) {
                    this.emailtext = 'Please enter a valid email address.';
                    this.emailflag = true
                } else {
                    this.emailtext = '';
                    this.emailflag = false
                }
            }
        },
        bindeEmailChange(){
            if(this.email == ''){
                this.emailflagfocus = false
            }else{
                this.emailflagfocus = true
            }
            
        },
        handleCloseEmail(){
            this.email = ""
            this.emailflagfocus =  false
            this.emailflag = false
        }

    }
   
  }
  </script>
  
  <style scoped >
        @import '@/assets/css/home.css'
  
  </style>
  